/**
This will remove .alignfull or .alignwide classes from blocks that are in line with the vertical navigation
 */

const secondaryNav = document.querySelector('.audience-hero__subnavigation');
const contentArea = document.querySelector('.entry-content');

if (secondaryNav && contentArea) {
    const secondaryNavBox = secondaryNav.getBoundingClientRect();
    const secondaryNavBottomCoordinate = secondaryNavBox['bottom'];
    const fullwidthElements = contentArea.querySelectorAll('.alignfull, .alignwide');

    if (fullwidthElements.length > 0){
        fullwidthElements.forEach((item) => {
            let itemBox = item.getBoundingClientRect();
            let itemTopCoordinate = itemBox['top'];
    
            if (itemTopCoordinate < secondaryNavBottomCoordinate) {
                item.classList.remove('alignfull');	
                item.classList.remove('alignwide');	
            }
        });
    }
}