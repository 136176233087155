const counterUp = window.counterUp.default

require( 'waypoints/lib/noframework.waypoints.js' )
let counters = document.querySelectorAll( '.js-counterup' );
let entries = [...counters];
entries.forEach(el => {
    new Waypoint({
        element: el,
        handler: function () {
            counterUp(el, {
                duration: 2500,
                delay: 100
            })
            this.destroy()
        },
        offset: 'bottom-in-view',
    })
})